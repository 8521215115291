import React, { createContext, useContext, useState } from "react";
import LocalizedStrings from "react-localization";

const TranslationContext = createContext({
  language: "fr",
  setLanguage: () => null,
});

const TranslationProvider = ({ children }) => {
  const [language, setLanguage] = useState("fr");

  return (
    <TranslationContext.Provider value={{ language, setLanguage }}>
      {children}
    </TranslationContext.Provider>
  );
};

const useTranslation = (_strings, key) => {
  const { language, setLanguage } = useContext(TranslationContext);

  const strings = new LocalizedStrings(_strings || { en: {} });
  strings.setLanguage(language);

  return { language, setLanguage, strings };
};

export { useTranslation, TranslationProvider, TranslationContext };
