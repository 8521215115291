import "normalize.css";

import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faFacebook,
  faLinkedin,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";
import {
  faBars,
  faCheck,
  faStopwatch,
  faTimes,
  faVoicemail,
} from "@fortawesome/free-solid-svg-icons";
import Modal from "react-modal";

import "./src/global.css";
import RootLayout from "./src/components/RootLayout";

library.add(
  faBars,
  faCheck,
  faFacebook,
  faLinkedin,
  faStopwatch,
  faTimes,
  faTwitter,
  faVoicemail
);

Modal.setAppElement("#___gatsby");

if ("serviceWorker" in navigator) {
  navigator.serviceWorker.ready.then((registration) => {
    registration.unregister();
  });
}

const wrapRootElement = ({ element }) => <RootLayout>{element}</RootLayout>;

// eslint-disable-next-line import/prefer-default-export
export { wrapRootElement };
